<template>
  <div class="info">
    <top-bar :title="'更多信息'" :left="true"/>
    <div class="">
      <van-cell-group>
<!--        <van-cell   >-->
<!--          <template #title>-->
<!--            <div class="blue-box"></div>-->
<!--            <span class="custom-title">居民信息</span>-->
<!--          </template>-->
<!--        </van-cell>-->
        <van-cell title="上传照片" :class="isOld == 1 ? 'oldSize' : ''">
          <template #right-icon>
            <div class="headbox">
              <div class="Avatar">
                <img :src="myDetailInfo.headImg" alt="">
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell title="有效证件号" :value="myDetailInfo.idNumber || '无'" :class="isOld == 1 ? 'oldSize' : ''" :value-class="!idNumberCheck ? 'redTip' : ''"/>
        <van-cell title="姓名" :value="myDetailInfo.name || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="性别" :value="myDetailInfo.sex || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="手机号码" :value="myDetailInfo.mobile || '无'" :class="isOld == 1 ? 'oldSize' : ''" :value-class="!mobileCheck ? 'redTip' : ''"/>
        <van-cell title="民族" :value="myDetailInfo.nationStr || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="国籍" :value="myDetailInfo.nationalityStr || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="出生日期" :value="myDetailInfo.birthday || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell style="background-color: #F5F5F5;padding: 0.15rem 0.4rem;" :border="false"/>
        <van-field  type="textarea" rows="1" :autosize="{minHeight: 26}" v-model="myDetailInfo.company"  label="工作单位"  placeholder="无"  input-align="right" readonly :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="户籍性质" :value="myDetailInfo.registryTypeStr || '未知'" readonly :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-field  type="textarea" rows="1" :autosize="{minHeight: 26}" v-model="myDetailInfo.regAddress"  label="户籍地址"  placeholder="无"  input-align="right" readonly :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="紧急联系人" :value="myDetailInfo.emContact || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="联系人号码" :value="myDetailInfo.emMobile || '无'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-field  type="textarea" rows="1" :autosize="{minHeight: 26}" v-model="myDetailInfo.nowAddress"  label="现居住地"  placeholder="无"  input-align="right" readonly :class="isOld == 1 ? 'oldSize' : ''"/>
        <!-- <van-cell title="隔间房号" :value="myDetailInfo.buildingRoom || '未知'" /> -->
      </van-cell-group>
      <van-row class="updateBtn">
        <van-col span="24">
          <van-button type="info" round block @click="changeInfo" :style="{fontSize: isOld == 1 ? '18px' : '16px'}">编辑</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import TopBar from '../../components/topBar/topBar.vue'

import {getImageStream} from '@/utils/index'
import {desensitization} from '@/utils/utils'
import {isNULL, IdentityCodeValid, isMobile} from '@/utils/validate'
export default {
  components: {
    topBar
  },
  data () {
    return {
      myDetailInfo: {},
      nameCheck: false,
      idNumberCheck: false,
      mobileCheck: false,
      birthdayCheck: false,
      registryCheck: false,
      isOld: null,
    }
  },
  methods: {
    getDetailInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/jmggDetailInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.myDetailInfo = data.userInfoEntity
          let defaultImg = getImageStream('files/wx/images/content/header-default.png')
          this.myDetailInfo.headImg = data.userInfoEntity.headImg ? getImageStream(data.userInfoEntity.headImg) : defaultImg
          this.myDetailInfo.sex = data.userInfoEntity.sex == 1 ?'男':'女'
          if (this.myDetailInfo.idNumberType == 7) {
            this.myDetailInfo.idNumber = ' '
          }
          if (!isNULL(this.myDetailInfo.name)) {
            this.nameCheck = true
          }
          if (IdentityCodeValid(this.myDetailInfo.idNumber) && !isNULL(this.myDetailInfo.idNumber)) {
            this.idNumberCheck = true
          }
          if (isMobile(this.myDetailInfo.mobile) && !isNULL(this.myDetailInfo.mobile)) {
            this.mobileCheck = true
          }
          if (!isNULL(this.myDetailInfo.birthday)) {
            this.birthdayCheck = true
          }
          if (!isNULL(this.myDetailInfo.registryType)) {
            this.registryCheck = true
          }
          this.isOld = data.userInfoEntity.extended ? JSON.parse(data.userInfoEntity.extended).isOld : null
        } else {
        this.$toast.fail(data.msg);
        }
      })
    },
    changeInfo () {
      this.$dialog.confirm({
        message: '确认编辑？',
      }).then(() => {
        this.$router.push('/my-info-change')
      }).catch(() => {
      });
    }
  },
  created () {
    this.getDetailInfo()
  }
}

    TopBar</script>

<style lang="scss" scoped>
.oldSize {
  ::v-deep .van-cell__title{
    font-size: 40px !important;
  }
  ::v-deep .van-cell__value {
    font-size: 40px !important;
  }
  ::v-deep .van-field__control {
    font-size: 40px !important;
  }
}
.info {
  padding: 20px 0 40px;
  background-color: #F5F5F5;
  .van-cell {
    padding: 26px 30px;
    &:first-child .van-cell__title{
      line-height: 120px;
      flex: 1 !important;
    }
    &:first-child .van-cell__value{
      flex: 1 !important;
    }
    &:nth-child(3) {
      ::v-deep .van-field__label {
        width: 100px !important;
      }
    }
    ::v-deep .van-cell__title{
      font-size: 34px;
      color: #666666;
      font-family: PingFangSC-Regular, PingFang SC;
      //width: auto;
      margin-right: 80px;
      flex: unset !important;
    }
    ::v-deep .van-cell__value {
      font-size: 34px;
      //color: #333333;
      text-align: unset;
      overflow: unset;
      flex: unset !important;
      -webkit-box-flex: unset !important;
      -webkit-flex: none !important;
      //padding-left: 20px;
    }
    ::v-deep .van-field__control {
      font-size: 34px;
      //color: #333333;
      text-align: left;
    }
    ::v-deep .van-field__body {
      width: 6rem;
    }
    ::v-deep .van-field__label {
      //width: unset;
      margin-right: 30px;
    }
    ::v-deep .van-cell__right-icon {
      flex: 1;
      text-align: right;
    }
    ::v-deep .van-uploader__input-wrapper {
      display: flex;
    }
  }
}
.widthSet {
  ::v-deep .van-field__label {
    width: 5.3em !important;
  }
  ::v-deep .van-field__control {
    //width: unset !important;
  }
}
.headbox {
  background-color: #fff;
  padding-right: 20px;
  //margin: 30px;
  //padding: 30px;
  .Avatar {
    width: 100%;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
      overflow: hidden;
    }
  }
  .headImgbtn {
    height: 80px;
    margin-top: 30px;
    button {
      width: 180px;
      height: 70px;
    }
  }
}
.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.updateBtn .van-button{
  //position: fixed;
  //left: 30px;
  //bottom: 40px;
  width: 690px;
  height: 88px;
  background: #4581F8;
  border-radius: 8px;
  margin-top: 78px;
  margin-left: 30px;
}
.redTip {
  color: red;
}
.blackTip {
  color: #333333;
}
</style>
